<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <div class="app__content app__content--tradein">
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  link: [
    { rel: "icon", href: "/favicon.ico", sizes: "any" },
    { rel: "icon", href: "/favicon.svg", type: "image/svg+xml" },
    { rel: "apple-touch-icon", href: "/apple-touch-icon.png" }
  ]
});
</script>
